import React, { Component } from 'react';
import { connect } from 'react-redux';


import InputTitle from './InputTitle';

export class ChannelSelect extends Component {
    renderDescription = () => {
        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}></div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };

    renderSelectedChannel = () => {
        if (this.props.value && this.props.value.name) {
            return <option value={JSON.stringify(this.props.value)}>#{this.props.value.name}</option>;
        }
        return null;
    };

    renderChannels = () => {
        if (this.props.server_data.channels && this.props.server_data.channels.length > 0) {
            return this.props.server_data.channels.map((channel) => {
                if (this.props.settings.channel_filter === undefined && channel.type === 0 ||
                    (this.props.settings.channel_filter && this.props.settings.channel_filter.length > 0 &&
                        this.props.settings.channel_filter.includes(channel.type))) {
                    console.log(this.props.settings.channel_filter, channel.type, channel.name, 'NAME');
                    return (
                        <option key={channel.id} value={JSON.stringify({
                            id: channel.id,
                            name: channel.name
                        })}>
                            #{channel.name}
                        </option>
                    );
                }
                return null;
            }).filter(Boolean); // Remove null entries
        }
        return null;
    };

    render() {
        return (
            <>
                <div className={`bg-menu-color rounded-lg p-6 w-full`}>
                    <div className="">
                        <InputTitle settings={this.props.settings} refresh={true} />
                        <div className="section-content-header mb-2">
                            {this.renderDescription()}
                        </div>
                        <div>
                            <select
                                value={JSON.stringify(this.props.value)}
                                onChange={(e) => {
                                    const value = JSON.parse(e.target.value);
                                    console.log(value, 'TEST');
                                    this.props.change(value);
                                }}
                                className='select select-bordered w-full'
                            >
                                <option disabled value={JSON.stringify({ id: "", name: "" })}>Pick a Channel</option>
                                {this.renderSelectedChannel()}
                                {this.renderChannels()}
                            </select>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    server_data: state.data.server_data
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSelect);
